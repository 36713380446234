import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  Content,
  Iterator,
  StaffTeamMember,
} from "@bluefin/components";
import { Header, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"about-hero"}>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
          subfooter={false}
          className={"about-page"}
        >
          <Content className={"about-content"}>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={16}>
              <Header as={"h1"}>About Us</Header>
            </Content.Markup>
            <Content.Markup width={16}>
              {fishermanBusiness.aboutMarkdown}
            </Content.Markup>
          </Content>
          <div className={"team-container"}>
            <Header as={"h1"}>Our Team</Header>
            <Card.Group itemsPerRow={4} doubling={true} stackable={true}>
              <Iterator
                iterator={allFishermanBusinessTeam.nodes}
                key={"name"}
                component={StaffTeamMember}
                propMap={{
                  name: "name",
                  role: "role",
                  email: "email",
                  phone: "phone",
                  description: "description",
                  photo: "gatsbyImage",
                  social: "socialMedia",
                  hours: "hours",
                  locations: "locations",
                }}
              />
            </Card.Group>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      aboutMarkdown
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessTeam(filter: { show: { eq: true } }) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
